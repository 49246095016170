import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import styles from "../css/postlist.module.css"
import Image from 'gatsby-image'

export const query = graphql`
   {
  file(relativePath: {eq: "Ilustracionmano.jpg"}){
    childImageSharp{
      fluid{
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }multiple: file(relativePath: {eq: "MultipleLogo.jpg"}){
    childImageSharp{
      fluid{
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  } digipak: file(relativePath: {eq: "portadas.jpg"}){
    childImageSharp{
      fluid{
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }drinkme: file(relativePath: {eq: "DrinkMe.jpg"}){
    childImageSharp{
      fluid{
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
}
`
const IndexPage = ({ data }) => {


    const author = 'Yukuri'
    return (
        <Layout>
            <SEO title="Projects" />



            <section className={styles.posts}>

                <div className={styles.center}>

                    <article className={styles.card}>
                        <div className={styles.image}>
                            <Image fluid={data.multiple.childImageSharp.fluid} />                          </div>
                        <div className={styles.info}>
                            <div>
                                <h2>Multiple</h2>
                                <h6>
                                    <span>by {author}</span>
                                </h6>
                                <p>Realicé la creación del branding de la marca Múltiple. Una marca de calzoncillos para el hombre
                                actual. La esencia de la marca es la de una marca joven y fresca, orientada a un público joven y joven
                                adulto.
                   </p>
                                <Link to='/multiple' className={styles.link}>
                                    read more
          </Link>
                            </div>
                        </div>
                    </article>

                </div>
            </section>
            <section className={styles.posts}>

                <div className={styles.center}>

                    <article className={styles.card}>
                        <div className={styles.image}>
                            <Image fluid={data.drinkme.childImageSharp.fluid} />                          </div>
                        <div className={styles.info}>
                            <div>
                                <h2>DrinkMe</h2>
                                <h6>
                                    <span>by {author}</span>
                                </h6>
                                <p>Este proyecto consiste en la creación del naming y el branding de una marca.
                                Elegí una marca ecológica de tes, destinada un público joven, ofreciendo mezclas e infusiones
                                para diferentes momentos del día.
   </p>
                                <Link to='/drinkme' className={styles.link}>
                                    read more
</Link>
                            </div>
                        </div>
                    </article>

                </div>
            </section>
            <section className={styles.posts}>

                <div className={styles.center}>

                    <article className={styles.card}>
                        <div className={styles.image}>
                            <Image fluid={data.file.childImageSharp.fluid} />                          </div>
                        <div className={styles.info}>
                            <div>
                                <h2>Hechizo</h2>
                                <h6>
                                    <span>by {author}</span>
                                </h6>
                                <p>Proyecto final de grado para el curso de Serigrafía artística de la Escuela de Arte de León.
   </p>
                                <Link to='/hechizo' className={styles.link}>
                                    read more
</Link>
                            </div>
                        </div>
                    </article>

                </div>
            </section>

            <section className={styles.posts}>

                <div className={styles.center}>

                    <article className={styles.card}>
                        <div className={styles.image}>
                            <Image fluid={data.digipak.childImageSharp.fluid} />                          </div>
                        <div className={styles.info}>
                            <div>
                                <h2>Digipak Skott</h2>
                                <h6>
                                    <span>by {author}</span>
                                </h6>
                                <p>Este fue un proyecto para clase. El proyecto era la creación de un digipack y el CD del interior.
                                Me encanta la música de Skott una artista sueca de música indie, asi que pense que era la mejor
                                oportunidad para hacer una portada ficticia de su trabajo.
   </p>
                                <Link to='/digipak' className={styles.link}>
                                    read more
</Link>
                            </div>
                        </div>
                    </article>

                </div>
            </section>
        </Layout>
    )
}

export default IndexPage

